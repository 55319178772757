/*!

=========================================================
* ROUTE DASHBOARD CLIENT
=========================================================

*/

// DashBoard Client

import Dashboard from "layouts/dashboard";
import servers from "layouts/servers";
import OneProvider from "layouts/oneprovider";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
//import Profile from "layouts/profile";
import Users from "layouts/profile";
import ProfileActivity from "layouts/profile/components/Activity";

import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Logout from "layouts/authentication/logout";

import TerminalServers from "layouts/terminalservers";

//SERVER CONTENAIR HORS ADP
import servershadp from "layouts/servers copy";
import TerminalServershadp from "layouts/terminalservers copy";

import { IoRocketSharp } from "react-icons/io5";
import { IoIosDocument } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { IoBuild } from "react-icons/io5";
import { BsCreditCardFill } from "react-icons/bs";
import { IoStatsChart } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { SiDatacamp } from "react-icons/si";

// Custom Icon SlideBar Utils
import { FaDatabase } from "react-icons/fa6";
import { SiWebmin } from "react-icons/si";
import { FaServer } from "react-icons/fa6";
import Admin from "layouts/admin";

// Custom OneProvider
import OneProviderInvoiceList from "layouts/oneprovider/component/OneProviderInvoiceList";
import OneProviderInvoiceDetails from "layouts/oneprovider/Invoices";
import ListUser from "layouts/admin/Users/ListUsers";
import EditUser from "layouts/admin/Users/EditUsers";



const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "",
    route: "/",
    icon: <IoHome size="15px" color="inherit" />,
    component: Dashboard,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Servers",
    key: "server",
    route: "/server",
    icon: <FaServer size="15px" color="inherit" />,
    component: servers,
    noCollapse: true,
  },
  {
    route: "/server/:uid",
    component: TerminalServers,
  },
  {
    type: "collapse",
    name: "Servers -[HORSADP]-",
    key: "serverhadp",
    route: "/server_hadp",
    icon: <FaServer size="15px" color="inherit" />,
    component: servershadp,
    noCollapse: true,
  },
  {
    route: "/server_hadp/:uid",
    component: TerminalServershadp,
  },
  {
    route: "/tables",
  },
  {
    route: "/billing",
    component: Billing,
  },
  //{ type: "title", title: "Account Pages", key: "account-pages" },
  {
    route: "/profile",
    component: Users,
  },
  {
    route: "/profile/activity",
    component: ProfileActivity,
  },
  {
    route: "/login",
    component: SignIn,
  },
  {
    route: "/register",
    component: SignUp,
  },
  {
    route: "/logout",
    component: Logout,
  },



  // Utils
  {
    type: "collapse",
    name: "Webmin",
    href: "https://web.mariabird.net:81",
    icon: < SiWebmin size="15px" color="inherit" />,
  },
  {
    type: "collapse",
    name: "DataBase V2",
    href: "https://db2.mariabird.net",
    icon: < FaDatabase size="15px" color="inherit" />,
  },
  {
    type: "collapse",
    name: "OneProvider",
    key: "Oneprovider",
    route: "/Oneprovider",
    component: OneProvider,
    icon: < SiDatacamp size="15px" color="inherit" />,
  },
  {
    route: "/Oneprovider/invoices",
    component: OneProviderInvoiceList,
  },
  {
    route: "/Oneprovider/invoices/:invoiceId",
    component: OneProviderInvoiceDetails,
  },
  {
    route: "/!/wl/admin",
    component: Admin,
  },
  {
    route: "/!/wl/admin/Users",
    component: ListUser,
  },
  {
    route: "/!/wl/admin/Users/edit/:q/:u",
    component: EditUser,
  }  
  
];
export default routes;
