import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Button, Select, MenuItem } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import './invoicesTable.css';
import nodemailer from 'nodemailer';

const OneProviderInvoiceList = () => {
  const [invoices, setInvoices] = useState([]);
  const [filter, setFilter] = useState('All');
  const [maxToShow, setMaxToShow] = useState(12);
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);
  const history = useHistory();

  const api_key = 'API_vkHlW2ZgqbtNNVvl8tTc4s7YsXQgfMga';
  const client_key = 'CK_lpaOIX5ciZ4MsmDJawGtGXbFF9Bq4mNP';

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get('https://apiop.mariabird.net/invoices', {
          headers: {
            'Api-Key': api_key,
            'Client-Key': client_key,
            'X-Pretty-JSON': '1',
          }
        });
        if (response.data && response.data.response && response.data.response.invoices) {
          setInvoices(response.data.response.invoices);
          const unpaid = response.data.response.invoices.filter(invoice => invoice.status === 'Unpaid');
          setUnpaidInvoices(unpaid);
          sendEmailForUnpaidInvoices(unpaid);
        }
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };
    fetchInvoices();
  }, []);

  const filteredInvoices = invoices
    .filter(invoice => filter === 'All' || invoice.status === filter)
    .slice(0, maxToShow);

  const viewInvoiceDetails = (invoiceId) => {
    history.push(`/Oneprovider/invoices/${invoiceId}`);
  };

  const sendEmailForUnpaidInvoices = (unpaidInvoices) => {
    const transporter = nodemailer.createTransport({
      host: 'sys.mrapi.net',
      port: 465,
      secure: true,
      auth: {
        user: 'notification@sys.mrapi.net',
        pass: 'fs$._kVBUAmR'
      }
    });

    const mailOptions = {
      from: 'notification@sys.mrapi.net',
      to: 'mrkey2b@gmail.com', 
      subject: 'Unpaid Invoices',
      text: `There are ${unpaidInvoices.length} unpaid invoices.`, 
      html: `<p>There are ${unpaidInvoices.length} unpaid invoices.</p>`
    };

    transporter.sendMail(mailOptions, (error, info) => {
      if (error) {
        console.error('Error sending email:', error);
      } else {
        console.log('Email sent:', info.response);
      }
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div>
        <Button onClick={() => setFilter('All')}>All</Button>
        <Button onClick={() => setFilter('Paid')}>Paid</Button>
        <Button onClick={() => setFilter('Unpaid')}>Unpaid</Button>
        <Button onClick={() => setFilter('Cancelled')}>Cancelled</Button>
        <Select
          value={maxToShow}
          onChange={(e) => setMaxToShow(e.target.value)}
        >
          {[5, 12, 15, 20].map((number) => (
            <MenuItem key={number} value={number}>{number}</MenuItem>
          ))}
        </Select>
      </div>
      <div className="invoice-table-container">
        <table className="invoice-table">
          <thead>
            <tr>
              <th>INVOICE ID</th>
              <th>STATUS</th>
              <th>CREATION DATE</th>
              <th>DUE DATE</th>
              <th>PAID DATE</th>
              <th>BALANCE</th>
              <th>CREDIT</th>
              <th>DUE TOTAL</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {filteredInvoices.map((invoice, index) => (
              <tr key={index}>
                <td>{invoice.id}</td>
                <td className={`status ${invoice.status.toLowerCase()}`}>{invoice.status}</td>
                <td>{invoice.creation_date}</td>
                <td>{invoice.due_date}</td>
                <td>{invoice.paid_date}</td>
                <td>{invoice.balance}</td>
                <td>{invoice.credit}</td>
                <td>{invoice.sub_total}</td>
                <td><button
                  className="view-invoice-btn"
                  onClick={() => viewInvoiceDetails(invoice.id)}
                >
                  VIEW INVOICE
                </button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
};

export default OneProviderInvoiceList;

