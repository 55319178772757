import React from 'react';
import { Link } from 'react-router-dom';
import "layouts/servers/list.css"

const ServerComponent = ({ uid, name, status, ip, port }) => {

  const displayStatus = status === 'running' ? 'Online' : status === 'offline' ? 'Offline' : status === 'starting' ? 'Starting' : status === 'stopping' ? 'Stopping' : status;


  return (
    <Link to={`/server_hadp/${uid}`} className="server-card" style={{ textDecoration: 'none' }}>
      <span className="server-name">{name}</span>
      <span className="server-ip">{ip}:{port}</span>
      <span className={`server-status ${status}`}>{displayStatus}</span>
    </Link>
  );
};

export default ServerComponent;