import React, { useEffect, useState } from 'react';
import ServerComponent from './ServerComponent';
import { useAuth } from 'context/AuthContext';

const ServerListComponent = () => {
  const { isAuthenticated } = useAuth();
  const [servers, setServers] = useState([]);
  const [authorizedUids, setAuthorizedUids] = useState([]);

  const getCookie = (name) => {
    const cookieArr = document.cookie.split(';');
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split('=');
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  };

  useEffect(() => {
    if (isAuthenticated) {
      const token = getCookie('adp_session'); // Utiliser getCookie localement

      fetch('https://api.mariabird.net/server-access', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Réponse réseau non OK');
        }
        return response.json();
      })
      .then(data => {
        setAuthorizedUids(data.authorizedUids);
      })
      .catch(error => console.error('Error fetching authorized UIDs:', error));
    }
  }, [isAuthenticated]);
  

  useEffect(() => {
    fetch('#rien')
      .then(response => response.json())
      .then(data => {
        // Filtrer les serveurs en fonction des UID autorisés
        const filteredServers = data.filter(server => authorizedUids.includes(server.uid));
        setServers(filteredServers);
      })
      .catch(error => console.error('Error fetching servers:', error));
  }, [authorizedUids]); // Dépendance à authorizedUids

  return (
    <div style={{ padding: '20px' }}>
      {servers.map(server => (
        <ServerComponent uid={server.uid} name={server.name} status={server.status} ip={server.ip_alias} port={server.port} />
      ))}
    </div>
  );
};

export default ServerListComponent;
